import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  keysToCamel,
  resolveByPrefix,
} from "@dataResolvers";

const query = graphql`
  query AnnouncementQuery {
    prismicGlobals {
      id
      data {
        announcement_copy {
          html
          raw
        }
        announcement_cta_text
        announcement_cta_link {
          url
          target
        }
        announcement_is_live
        announcement_scheduled {
          announcement_scheduled_link_text
          announcements_scheduled_link {
            url
            target
          }
          announcement_scheduled_date
          announcement_scheduled_copy {
            html
            raw
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { prefixed } = resolveByPrefix(prismicGlobals.data, ["announcement_"]);
  const { announcement } = prefixed;

  // take the array of scheduled announcements and sort them in ascending order
  // and then only grab the first announcement
  const [scheduled] = announcement.scheduled
    .map(a => keysToCamel({ ...a }))
    .map(a => ({
      ...a,
      copy: a.announcementScheduledCopy,
      ctaLink: a.announcementsScheduledLink,
      ctaText: a.announcementScheduledLinkText,
      date: a.announcementScheduledDate,
    }))
    .sort((a, b) => new Date(a.date).valueOf() > new Date(b.date).valueOf())
    .filter(a => Date.parse(`${a.date} GMT-08:00`) >= Date.now());

  // check if there are scheduled announcements that are ready to show
  // if not use the default
  const activeAccouncement = scheduled || announcement;

  return keysToCamel({
    ...activeAccouncement,
    isLive: announcement.is_live === "Yes",
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
